exports.components = {
  "component---src-pages-atelier-js": () => import("./../../../src/pages/atelier.js" /* webpackChunkName: "component---src-pages-atelier-js" */),
  "component---src-pages-batole-sitter-narozeniny-js": () => import("./../../../src/pages/batole-sitter-narozeniny.js" /* webpackChunkName: "component---src-pages-batole-sitter-narozeniny-js" */),
  "component---src-pages-foceni-js": () => import("./../../../src/pages/foceni.js" /* webpackChunkName: "component---src-pages-foceni-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-newborn-js": () => import("./../../../src/pages/newborn.js" /* webpackChunkName: "component---src-pages-newborn-js" */),
  "component---src-pages-rodina-par-tehotenstvi-portret-js": () => import("./../../../src/pages/rodina-par-tehotenstvi-portret.js" /* webpackChunkName: "component---src-pages-rodina-par-tehotenstvi-portret-js" */),
  "component---src-pages-svatby-js": () => import("./../../../src/pages/svatby.js" /* webpackChunkName: "component---src-pages-svatby-js" */)
}

